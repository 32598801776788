import React from 'react'
import { Link } from 'react-router-dom'

const TabICPA = ({ idValue, listTab = [], idICPA, ...props }) => {
  return (
    <div
      style={{ display: 'flex', overflow: 'hidden', overflowX: 'auto' }}
      className='pl-0 pr-0 mb-20 tab-customer-banner-customer'
    >
      {listTab.map((i) => {
        return (
          <>
            {idValue === i.id ? (
              <div
                to={`/icpa/edit/${idICPA}?idCompany=${i.id}&tab=overview`}
                className='hover-span-action tab-customer tab-customer-active'
                data-toggle='tooltip'
                data-placement='top'
                title={i.name}
              >
                {i.name}
              </div>
            ) : (
              <Link
                to={`/icpa/edit/${idICPA}?idCompany=${i.id}&tab=overview`}
                className='hover-span-action tab-customer'
                data-toggle='tooltip'
                data-placement='top'
                title={i.name}
              >
                {i.name}
              </Link>
            )}
          </>
        )
      })}
    </div>
  )
}

export default TabICPA
