import React from "react";

const EyeFillIcon = ({ fill = "#768394" }) => {
	return (
		<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1.24672 9.8645L1.22183 10L1.24672 10.1355C2.09469 14.7519 6.13799 18.25 10.9999 18.25C15.8618 18.25 19.9051 14.7519 20.7531 10.1355L20.778 10L20.7531 9.8645C19.9051 5.24807 15.8618 1.75 10.9999 1.75C6.13799 1.75 2.09469 5.24806 1.24672 9.8645ZM14.4166 10C14.4166 11.887 12.8869 13.4167 10.9999 13.4167C9.11292 13.4167 7.58322 11.887 7.58322 10C7.58322 8.11303 9.11292 6.58333 10.9999 6.58333C12.8869 6.58333 14.4166 8.11303 14.4166 10ZM7.74989 10C7.74989 11.795 9.20498 13.25 10.9999 13.25C12.7948 13.25 14.2499 11.795 14.2499 10C14.2499 8.20502 12.7948 6.75 10.9999 6.75C9.20498 6.75 7.74989 8.20502 7.74989 10Z"
				fill={fill}
				stroke="white"
				stroke-width="1.5"
			/>
		</svg>
	);
};
export default EyeFillIcon;
