import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { Modal, Button } from 'antd'

export default function ModalConfirm(props) {
    return (
        <Modal
            className='lms-modal lms-modal-noti'
            title={props.title}
            visible={props.visible}
            onCancel={props.onCancel}
            width={props.width}
            footer={null}
            bodyStyle={props.bodyStyle}
            closable={props.closable}
            closeIcon={
                <img
                    className='close-circle-fill'
                    style={{ width: '24px', height: 'auto' }}
                    src={'/images/button-icon/close-circle.svg'}
                    alt={'close'}
                />
            }
        >
            <div>
                <div className={props?.contentCLassName ?? 'mb-40'}>
                    {props.content}
                </div>
                {props?.footerContent ?? (
                    <Grid container spacing={1}>
                        <Grid container item xs={6}>
                            <Button
                                variant='secondary'
                                type='button'
                                className='lms-secondary-btn'
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    marginRight: 5,
                                }}
                                onClick={props.onCancel}
                            >
                                {props?.cancelText ?? (
                                    <FormattedMessage id='PAGE.BUTTON.SPAN.CANCEL' />
                                )}
                            </Button>
                        </Grid>
                        <Grid container item xs={6}>
                            <Button
                                variant='primary'
                                type='submit'
                                className='lms-btn'
                                style={{
                                    width: '100%',
                                    height: '40px',
                                }}
                                onClick={props.onConfirm}
                            >
                                {props?.okText ?? 'OK'}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </div>
        </Modal>
    )
}
