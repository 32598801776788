import React from 'react'

const DownloadIcon = ({ fill = '#8F9BBA' }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.83352 17.4844C4.69523 17.397 3.61366 16.9522 2.74324 16.2134C1.87282 15.4747 1.25806 14.4798 0.986737 13.3709C0.715418 12.262 0.801413 11.0957 1.23244 10.0385C1.66347 8.98138 2.4175 8.08745 3.38686 7.48438C3.59312 5.87612 4.37835 4.39813 5.59562 3.32701C6.81289 2.25589 8.37876 1.66504 10.0002 1.66504C11.6216 1.66504 13.1875 2.25589 14.4048 3.32701C15.622 4.39813 16.4073 5.87612 16.6135 7.48438C17.5829 8.08745 18.3369 8.98138 18.7679 10.0385C19.199 11.0957 19.285 12.262 19.0136 13.3709C18.7423 14.4798 18.1276 15.4747 17.2571 16.2134C16.3867 16.9522 15.3051 17.397 14.1669 17.4844V17.5002H5.83352V17.4844ZM10.8335 10.0002V6.66688H9.16686V10.0002H6.66686L10.0002 14.1669L13.3335 10.0002H10.8335Z'
        fill={fill}
      />
    </svg>
  )
}

export default DownloadIcon
