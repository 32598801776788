/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import KTToggle from "../../_assets/js/toggle";
import * as builder from "../../ducks/builder";
import { ReactComponent as AngleDoubleIcon } from "../assets/layout-svg-icons/grid-4.svg";

class Brand extends React.Component {
	ktToggleRef = React.createRef();

	componentDidMount() {
		// eslint-disable-next-line no-undef
		new KTToggle(this.ktToggleRef.current, this.props.toggleOptions);
	}

	render() {
		let logo = "/images/T5.png";
		return (
			<div className={`kt-aside__brand kt-grid__item ${this.props.brandClasses}`} id="kt_aside_brand" style={{
				borderBottom: "1px", borderBottomColor: "#3B445D"
			}}>
				<div className="kt-aside__brand-logo" style={{ textAlign: "center" }}>
					<Link to="/dashboard" style={{ fontSize: "20px", color: "#fff", fontWeight: "bold" }}>
						<img src={logo} alt="logo" />
						{/* MYTRAINING CENTER */}
					</Link>
				</div>

				{this.props.asideSelfMinimizeToggle && (
					<div>
						<Link to="/dashboard" className="kt-aside__toggler-brand-logo pt-4 px-2 pb-4" style={{ textAlign: "center", width: "64px", height: "auto" }}>
							<img src={'/images/menu-icon/t5.svg'} alt="logo" />
						</Link>
						<div className="kt-aside__brand-tools">
							<button ref={this.ktToggleRef} className="kt-aside__brand-aside-toggler" id="kt_aside_toggler" style={{ textAlign: "center" }}>
								<span className="ml-1">
									<AngleDoubleIcon />
								</span>
								<span>
									<div>
										<AngleDoubleIcon />
									</div>
								</span>
							</button>
						</div>
					</div>
				)
				}
			</div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		brandClasses: builder.selectors.getClasses(store, {
			path: "brand",
			toString: true,
		}),
		asideSelfMinimizeToggle: objectPath.get(store.builder.layoutConfig, "aside.self.minimize.toggle"),
		headerLogo: builder.selectors.getLogo(store),
		headerStickyLogo: builder.selectors.getStickyLogo(store),
		toggleOptions: {
			target: "body",
			targetState: "kt-aside--minimize",
			togglerState: "kt-aside__brand-aside-toggler--active",
		},
	};
};

export default connect(mapStateToProps)(Brand);
