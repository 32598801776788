import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { Modal, Button } from 'antd'

export default function ModalBase(props) {
    return (
        <Modal
            className='lms-modal lms-modal-noti'
            title={props.title}
            visible={props.visible}
            onCancel={props.onCancel}
            width={props.width}
            footer={null}
            bodyStyle={props.bodyStyle}
            closable={props.closable}
            closeIcon={
                <img
                    className='close-circle-fill'
                    style={{ width: '24px', height: 'auto' }}
                    src={'/images/button-icon/close-circle.svg'}
                    alt={'close'}
                />
            }
        >
            <div style={{
                marginTop: "-20px",
                marginLeft: "-20px",
                marginRight: "-20px"
            }}>
                <div className={props?.contentCLassName ?? 'mb-20'}>
                    {props.content}
                </div>
                <div className="d-flex justify-content-center">
                    <div className="d-flex">
                        { !props.disableCancelBtn ? (
                            <Button
                                variant='secondary'
                                type='button'
                                className='lms-secondary-btn'
                                style={{
                                    width: "150px",
                                    height: '40px',
                                    marginRight: 5,
                                }}
                                onClick={props.onCancel}
                                >
                            {props?.cancelText ?? (
                                <FormattedMessage id='PAGE.BUTTON.SPAN.CANCEL' />
                            )}
                        </Button>
                        ) : null }
                        { props.onConfirm ? (
                            <Button
                            variant='primary'
                                type='submit'
                                className='lms-btn'
                                style={{
                                    width: "150px",
                                    height: '40px',
                                }}
                                loading={props.loading}
                                onClick={props.onConfirm}
                            >
                            {props?.okText ?? 'OK'}
                        </Button>
                        ) : null }
                    </div>
                </div>
            </div>
        </Modal>
    )
}
