import React, { Component } from "react";
import { Link } from "react-router-dom";

class PageHeader extends Component {
	render() {
		let { title, breadcrumb } = this.props;
		let numberBreadcrumb = breadcrumb.length;
		return (
			<div className="page-header page-header-default">
				<div className="page-header-content">
					<div className="page-title">
						<h4>
							<i className="icon-graduation2 position-left"></i> <span className="text-semibold">{title}</span>
						</h4>
					</div>
				</div>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/dashboard">Dashboard</Link>
						</li>
						{breadcrumb.map((it, idx) => {
							if (idx + 1 === numberBreadcrumb) {
								return (
									<li className="breadcrumb-item active" key={idx} aria-current="page">
										{it.title}
									</li>
								);
							} else {
								return (
									<li key={idx} className="breadcrumb-item">
										<Link to={it.link}>{it.title}</Link>
									</li>
								);
							}
						})}
					</ol>
				</nav>
			</div>
		);
	}
}

export default PageHeader;
