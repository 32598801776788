import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Button, InputLabel, TextField } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { activeAccount } from "../../crud/auth.crud";
import { showErrorMessage, showSuccessMessageIcon } from "../../actions/notification";

function ActiveAccount(props) {
	const [isRequested, setRequested] = useState(false);
	const { intl } = props;
	const passRef = React.createRef();
	const rePassRef = React.createRef();
	if (isRequested) {
		return <Redirect to="/auth" />;
	}

	return (
		<div
			className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
			style={{ paddingTop: 185 }}
		>
			<div className="kt-login__body">
				<div className="kt-login__form">
					<div className="kt-login__title-2 mb-40">
						<h3>
							<FormattedMessage id="AUTH.REGISTER.SETPASS" />
						</h3>
					</div>

					<Formik
						initialValues={{ password: "", rePassword: "" }}
						onSubmit={(values, { setStatus, setSubmitting }) => {
							if (!values.password) {
								setSubmitting(false);
								passRef.current.focus();
								return showErrorMessage("Please enter password.");
							} else {
								if (values.password.length < 6) {
									setSubmitting(false);
									passRef.current.focus();
									return showErrorMessage("Password must be at least 6 characters.");
								}
								if (values.password.length > 20) {
									setSubmitting(false);
									passRef.current.focus();
									return showErrorMessage("Password must be longer than 20 characters.");
								}
								const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/;
								if (!regex.test(values.password)) {
									setSubmitting(false);
									passRef.current.focus();
									return showErrorMessage(
										"Password contains at least a upper case, lower case, symbol and a number. Except space character"
									);
								}
							}
							if (!values.rePassword) {
								setSubmitting(false);
								rePassRef.current.focus();
								return showErrorMessage("Please confirm password.");
							}

							if (values.password !== values.rePassword) {
								setSubmitting(false);
								passRef.current.focus();
								return showErrorMessage("Invalid. Password and re-password do not match.");
							}

							// let email = props.match.params.email;
							let strConfirm = props.match.params.strConfirm;

							activeAccount(strConfirm, values.password)
								.then(({ data }) => {
									if (data.signal) {
										showSuccessMessageIcon("Set password success. Please use your account to login system.");
										props.history.push("/auth/login");
									} else {
										setRequested(false);
										return showErrorMessage("Opps! Something was wrong!");
									}
								})
								.catch(() => {
									setSubmitting(false);
									setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.NOT_FOUND" }, { name: values.password }));
								});
						}}
					>
						{({ values, status, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
							<form onSubmit={handleSubmit} className="kt-form-2">
								{status && (
									<div role="alert" className="alert alert-danger">
										<div className="alert-text">{status}</div>
									</div>
								)}

								<div className="form-group">
									<InputLabel>Password</InputLabel>
									<TextField
										variant="outlined"
										size="small"
										type="password"
										margin="normal"
										className="kt-width-full mt-0"
										name="password"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.password}
										autoFocus
										inputRef={passRef}
									/>
								</div>

								<div className="form-group">
									<InputLabel>Re-password</InputLabel>
									<TextField
										variant="outlined"
										size="small"
										type="password"
										margin="normal"
										className="kt-width-full mt-0"
										name="rePassword"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.rePassword}
										inputRef={rePassRef}
									/>
								</div>

								<div className="kt-login__actions" style={{ justifyContent: "center" }}>
									{/* <Link to="/auth">
										<button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
											Back
										</button>
									</Link> */}

									<Button
										variant="contained"
										color="primary"
										type="submit"
										disabled={isSubmitting}
										className="btn-signin"
										style={{
											width: "125px",
											height: "40px",
										}}
									>
										Confirm
									</Button>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(connect(null, auth.actions)(ActiveAccount));
