/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react'

import { showErrorMessage } from '../../../actions/notification'
import makeRequest from '../../../libs/request'
import { CategoryComponent } from './CategoryComponent'
import NoDataComponent from './NoDataComponent'

export default function YourPublicReportComponent(props) {
  const { value = {}, show = false, tab } = props
  const [categoryList, setCategoryList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const getCompanyInfo = async () => {
    setIsLoading(true)
    makeRequest('get', `icpa/public-reports/category/get-all/${value?._id}`)
      .then(({ data }) => {
        setIsLoading(false)
        if (data?.signal) {
          let itemList = data?.data
          if (itemList && itemList?.length > 0 && itemList[0]) {
            let list = itemList.map((i) => {
              return {
                ...i,
              }
            })
            setCategoryList([...list])
          } else {
            setCategoryList([])
          }
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    if (value?._id && show) {
      getCompanyInfo()
    }
  }, [value, show, tab])

  return (
    <div className='mt-40'>
      {categoryList?.length > 0 ? (
        categoryList.map((i, index) => {
          return (
            <CategoryComponent
              key={index}
              value={i}
              defaultShow={index === 0}
            />
          )
        })
      ) : isLoading ? (
        <></>
      ) : (
        <NoDataComponent minHeight={'calc(100vh - 275px)'} />
      )}
    </div>
  )
}
