/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { WarningOutlined, SearchOutlined } from "@ant-design/icons";
import makeRequest from "../../libs/request";
import { showErrorMessage, showSuccessMessageIcon } from "../../actions/notification";
import HeaderBreadcrumb from "../../common/antd/component/HeaderBreadcrumb";
import ModalConfirm from "../../common/components/modal/ModalConfirm";
import ModalBase from "../../common/components/modal/ModalBase";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../loading";
import checkPermission from "../../libs/permission";
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from "@material-ui/core";
// import { isValidPhoneNumber } from "react-phone-number-input";
import { FormattedMessage } from "react-intl";
import { Spin, Modal, Select, Tag, Input } from "antd";
import { Form, Col } from "react-bootstrap";
const { Option } = Select;

const useStyles1 = makeStyles((theme) => ({
	root: {
		width: "100%",
		marginTop: theme.spacing(3),
		overflowX: "auto",
	},
	table: {
		minWidth: 650,
	},
}));

export default function ListCompany(props) {
	// Example 1
	const classes1 = useStyles1();
	const [page, setPage] = React.useState(0);
	const [size, setSize] = React.useState(10);
	const [totalRows, setTotalRows] = React.useState(0);
	const [rows, setRow] = useState([]);
	const [visible, setVisible] = useState(false);
	const [dataSearch, setDataSearch] = useState({ loadingPage: true, loadingTable: false, type: "ce" });
	const [dataDelete, setDataDelete] = useState({ visible: false });
	const [fetching, setFetching] = useState(false);
	const [cbList, setcbList] = useState([]);
	const [corpList, setcorpList] = useState([]);
	const [textSearch, setTextSearch] = useState("");
	const [dataAdd, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const permissions = {
		view: "commercial.view",
		create: "commercial.create",
		delete: "commercial.delete",
	};

	const getData = (page, perPage, keyword, status) => {
		makeRequest("get", `commercial-partners/getList`, { page, perPage, keyword, status, inCms: true })
			.then(({ data }) => {
				if (data.signal) {
					setRow(data.data.list);
					setTotalRows(data.data.total);
					setLoading(false);
				} else {
					return showErrorMessage(data.message);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const getDataList = (name) => {
		makeRequest("get", `cb/getListCreateAdmin`, { name, company: true })
			.then(({ data }) => {
				if (data.signal) {
					let cb = data.data.cb.map((it) => {
						return {
							label: `${it.name}`,
							value: `${it.id}_cb`,
						};
					});
					let corp = data.data.corporates.map((it) => {
						return {
							label: `${it.name}`,
							value: `${it.id}_cor`,
						};
					});
					setcbList(cb);
					setcorpList(corp);
					setFetching(false);
					setTextSearch(name);
				}
			})
			.catch((err) => {
				console.log("++++++++++++++++", err);
			});
	};
	useEffect(() => {
		if (checkPermission(permissions.view)) {
			getData(page, size, "", "all");
			getDataList("");
		} else {
			return showErrorMessage("No Permission.");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const unfilteredData = () => {
		setPage(0);
		setSize(10);
		setDataSearch({
			...dataSearch,
			status: "all",
			keyword: "",
		});
		getData(page, size, "", "all");
	};
	const handleChangePage = (event, newPage) => {
		getData(newPage, size, dataSearch.keyword, dataSearch.status);
		setPage(newPage);
	};

	const handleChangeSize = (event) => {
		setSize(parseInt(event.target.value, 10));
		setPage(0);
		getData(0, parseInt(event.target.value, 10), dataSearch.keyword, dataSearch.status);
	};

	const onChangeValue = (key, value) => {
		if (key === "type") {
			setData({
				...dataAdd,
				sub_id: null,
			});
		}
		setDataSearch({
			...dataSearch,
			[key]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		onChangeValue("loadingTable", true);
		setPage(0)
		getData(0, size, dataSearch.keyword, dataSearch.status);
		onChangeValue("loadingTable", false);
	};
	const showModal = (idDel) => {
		setDataDelete({
			...dataDelete,
			visible: true,
			idDel,
		});
	};

	const clickModalCancel = () => {
		setDataDelete({
			...dataDelete,
			visible: false,
			idDel: 0,
		});
	};

	const clickModalOk = () => {
		let id = dataDelete.idDel;
		makeRequest("post", `commercial-partners/destroy`, { id })
			.then(({ data }) => {
				if (data.signal) {
					showSuccessMessageIcon("Deleted successfully");
					setDataDelete({
						...dataDelete,
						visible: false,
						idDel: 0,
					});
					unfilteredData();
					setData({});
					getDataList("");
				} else {
					return showErrorMessage("Error", data.message);
				}
			})

			.catch((err) => {
				console.log("error", err);
			});
	};
	const renderStatusText = (row) => {
		if (row.status === 1) {
			return (
				<Tag bordered={false} color="success" className="tag-active">
					<FormattedMessage id="PAGE.LABLE.ACTIVE" />
				</Tag>
			);
		} else if (row.status === 2) {
			return (
				<Tag bordered={false} color="orange" className="tag-completed">
					<FormattedMessage id="PAGE.LABLE.APPROVED" />
				</Tag>
			);
		} else if (row.status === 3) {
			return (
				<Tag bordered={false} color="orange" className="tag-processing">
					<FormattedMessage id="PAGE.LABLE.PENDING" />
				</Tag>
			);
		} else if (row.status === 4) {
			return (
				<Tag bordered={false} color="orange" className="tag-expired">
					<FormattedMessage id="PAGE.LABLE.REJECTED" />
				</Tag>
			);
		} else if (row.status === 5) {
			return (
				<Tag bordered={false} color="orange" className="tag-expired">
					<FormattedMessage id="PAGE.LABLE.CANCEL" />
				</Tag>
			);
		} else {
			return (
				<Tag bordered={false} color="orange" className="tag-inactive">
					<FormattedMessage id="PAGE.LABLE.INACTIVE" />
				</Tag>
			);
		}
	};

	const fetchCB = (value) => {
		setcbList([]);
		setcorpList([]);
		setFetching(true);
		getDataList(value);
	};

	const onChangeCB = (value) => {
		const arrKey = value.split("_");
		let cb_id,
			corporate_id = null;
		if (dataSearch.type === "ce") {
			cb_id = null;
			corporate_id = arrKey[0];
		} else {
			cb_id = arrKey[0];
			corporate_id = null;
		}
		setData({
			...dataAdd,
			sub_id: value,
			corporate_id,
			cb_id,
			err_sub_id: false,
		});
	};
	const clickModalCreate = () => {
		if (!dataAdd.sub_id) {
			return showErrorMessage("Error", "Please choose a Partner name");
		}
		makeRequest("post", `commercial-partners/store`, dataAdd)
			.then(({ data }) => {
				if (data.signal) {
					setVisible(false);
					showSuccessMessageIcon("Add company successfully");
					getData(page, size, "");
					clickModalCancelCreate();
					getDataList("");
				} else {
					return showErrorMessage("Error", data.message);
				}
			})

			.catch((err) => {
				console.log("error", err);
			});
	};
	const clickModalCancelCreate = () => {
		setVisible(false);
		setDataSearch({
			...dataSearch,
			type: "ce",
		});
		setData({
			...dataAdd,
			sub_id: null,
		});
	};

	if (loading) {
		return <Loading />;
	}
	return (
		<>
			<HeaderBreadcrumb
				breadcrumb={[
					{
						title: 'Commercial Partners',
						link: '',
					}
				]}
			/>
			<div className="row pt-20">

				<div className="col-md-12">
					<div className="kt-section">
						<div className="kt-section__content">
							<Paper className={classes1.root}>
								<div className="search-form">
									<Form onSubmit={handleSubmit}>
										<div className='form-row'>
											<div className='form-group col-md-4'>
												<div className='form-group' style={{ display: 'flex' }}>
													{checkPermission(permissions.create) && (
														<div
															style={{
																paddingLeft: 0,
																paddingRight: 0,
															}}
														>
															<button
																onClick={() => setVisible(true)}
																className="btn btn-bold btn-icon-h text-light"
																style={{ backgroundColor: '#338FEB', width: 200, height: '40px' }}
																type="button"
															>
																<img className="button-add-circle-fill" style={{ width: '16px', height: 'auto' }} src={'/images/button-icon/add-circle-fill.svg'} alt={'Add'} />{' '}
																<span>
																	Add Company
																</span>
															</button>
														</div>
													)}
												</div>
											</div>
											<div className='form-group col-md-8'>
												<div className='form-group justify-content-end' style={{ display: 'flex' }}>
													<Input
														size='large'
														placeholder='Search'
														name='keyword'
														className='inline-block rounded border-color-grey border-radius-8'
														value={dataSearch.keyword || ''}
														style={{ width: 350 }}
														onChange={(e) => {
															onChangeValue('keyword', e.target.value)
														}}
														onPressEnter={handleSubmit}
														prefix={
															<SearchOutlined style={{ color: '#99A4C0' }} />
														}
													/>
												</div>
											</div>
										</div>
									</Form>
								</div>
								<div className="lms-tabs-list">
									<Table className={classes1.table}>
										<TableHead>
											<TableRow>
												<TableCell className="f-semibold_20 pl-16">Company Name</TableCell>
												<TableCell className="f-semibold_20">Type Company</TableCell>
												<TableCell className="f-semibold_20">Country</TableCell>
												<TableCell className="f-semibold_20">Subdomain</TableCell>
												<TableCell className="f-semibold_20">
													<FormattedMessage id="TABLE.CELL.STATUS" />
												</TableCell>
												{checkPermission(permissions.delete) ? <TableCell align="right" className="f-semibold_20 pr-16">Action</TableCell> : ""}
											</TableRow>
										</TableHead>
										<TableBody>
											{dataSearch.loadingTable ? (
												<TableRow>
													<TableCell colSpan={5} align="center">
														<Spin tip="Loading..." />
													</TableCell>
												</TableRow>
											) : rows.length ? (
												rows.map((row) => (
													<TableRow key={row.id}>
														<TableCell className="pl-16">
															<div style={{ display: "flex" }}>
																<div
																	style={{
																		background: `url(${row.cb ? row.cb.logo : row.corporate.logo
																			}) center center no-repeat`,
																		backgroundSize: "contain",
																		height: 50,
																		width: 50,
																	}}
																></div>
																<span style={{ paddingTop: 15 }}>
																	&nbsp;&nbsp;&nbsp;
																	{row.cb ? row.cb.name : row.corporate.name}
																</span>
															</div>
														</TableCell>
														<TableCell>{row.cb ? "Training Sales Entity" : "Competency Entity"}</TableCell>
														<TableCell>{row.cb ? row.cb.country : row.corporate.country}</TableCell>
														<TableCell>{row.cb ? row.cb.sub_domain : row.corporate.sub_domain}</TableCell>
														<TableCell>{renderStatusText(row.cb ? row.cb : row.corporate)}</TableCell>
														{checkPermission(permissions.delete) ? (
															<TableCell align="right" className="pr-16">
																<span
																	className="hover-span-action-remove"
																	onClick={(e) => showModal(row.id)}
																	data-toggle="tooltip"
																	data-placement="top"
																	title="Delete Company"
																>
																	<img
																		style={{ width: '20px', height: 'auto' }}
																		src={'/images/button-icon/delete.svg'}
																		alt={'Remove'}
																	/>
																</span>
															</TableCell>
														) : (
															""
														)}
													</TableRow>
												))
											) : (
												<TableRow>
													<TableCell colSpan={5} align="center">
														<FormattedMessage id="TABLE.CELL.NO_DATA_TO_DISPLAY" />
													</TableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>
									{Number(totalRows) > Number(size) && (
										<TablePagination
											className='table-pagination-customer'
											labelRowsPerPage={`Page ${Number(page) + 1} of ${Math.ceil(
												Number(totalRows) / Number(size)
											)}`}
											labelDisplayedRows={({ from, to, count }) => {
												return ''
											}}
											rowsPerPageOptions={[10, 20, 50]}
											component='div'
											count={totalRows}
											rowsPerPage={size}
											page={page}
											onChangePage={handleChangePage}
											onChangeRowsPerPage={handleChangeSize}
										/>
									)}

								</div>
							</Paper>
						</div>
					</div>
				</div>
				<ModalBase
					title={"Add Company"}
					visible={visible}
					onConfirm={clickModalCreate}
					onCancel={clickModalCancelCreate}
					okText="Confirm"
					content={
						<div className="pl-20 pr-16 pb-0">
							<Form.Row>
								<Form.Group as={Col} controlId="formBasicEmail">
									<Form.Label className="f-medium_18">
										Type Company
									</Form.Label>
									<select
										className="form-control inline-block"
										onChange={(e) => onChangeValue("type", e.target.value)}
										value={dataSearch.type || "all"}
									>
										<option value="ce">Competency Entity</option>
										<option value="tse">Training Sales Entity</option>
									</select>
								</Form.Group>
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col} controlId="formBasicEmail">
									<Form.Label className="f-medium_18">
										Partner
									</Form.Label>
									<Select
										className={
											dataAdd.err_sub_id
												? "has-error-select form-control partner-select"
												: "form-control partner-select"
										}
										showSearch
										value={dataAdd.sub_id}
										placeholder="Select partner"
										notFoundContent={fetching ? <Spin size="small" /> : textSearch ? "No data" : null}
										filterOption={false}
										onSearch={fetchCB}
										onChange={onChangeCB}
										suffixIcon={<img style={{ width: '12px', height: 'auto' }} src={'/images/button-icon/down-arrow.svg'} alt={'down-arrow'} />}
									>
										{dataSearch.type === "ce"
											? corpList.map((d) => (
												<Option key={`cor-${d.value}`} value={d.value}>
													{d.label}
												</Option>
											))
											: cbList.map((d) => (
												<Option key={`cb-${d.value}`} value={d.value}>
													{d.label}
												</Option>
											))}
									</Select>
								</Form.Group>
							</Form.Row>
						</div>
					}
				/>
				<ModalConfirm
					title="Delete Partner"
					visible={dataDelete.visible}
					onConfirm={clickModalOk}
					onCancel={clickModalCancel}
					cancelText="Cancel"
					okText="Ok"
					content={
						<p className="f-regular_20">
							<FormattedMessage id='MODEL.P.DO_DELETE' />
						</p>
					}
				/>
			</div>
		</>
	);
}
