import React, { useEffect, useState } from 'react'

import { Upload } from 'antd'
import RenderImage from './RenderImage'
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../../actions/notification'
import makeRequest from '../../../../libs/request'
import { URL_API } from '../../../../config/url'

export default function UploadComponent(props) {
  const [image, setImage] = useState()
  const [loadingImage, setLoadingImg] = useState(false)

  const beforeUploadImage = (file) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg'
    const size = file.size / 1024 / 1024
    const isLt2M = size < 5
    if (!isJpgOrPng) {
      showErrorMessage({ title: 'You can only upload JPEG/PNG file!' })
      return false
    } else if (!isLt2M) {
      showErrorMessage({ title: 'Logo size more than limit (5MB)' })
      return false
    }
    return isJpgOrPng
  }

  const removeFileThumbnailItem = async (url) => {
    // await deleteUrlLink({
    // 	arrayKey: url,
    // 	singleFile: true,
    // });
    props.onChange(null)
    setImage(null)
  }

  const handleChangeFile = (info) => {
    if (info.file.status === 'uploading') {
      setLoadingImg(true)
      return
    }
    if (info.file.status === 'error') {
      setLoadingImg(false)
      showErrorMessage(
        'Sorry, the system is having problems. Please try again!'
      )
      return
    }
    if (info.file.status === 'done') {
      setLoadingImg(false)
      showSuccessMessage({ title: 'Update logo successfully.' })
      const url = info.file.response.data
      setImage({ uid: info.fileList[0].uid, url: url })
      props.onChange(url)
    }
  }

  useEffect(() => {
    if (props?.value) {
      setImage({ uid: props.id, url: props?.value })
    } else {
      setImage(null)
    }
  }, [props?.value])

  return (
    <div className='d-flex item-center'>
      <div>
        {image && (
          <RenderImage
            image={image}
            key={image?.uid}
            removeFile={removeFileThumbnailItem}
          />
        )}
        <Upload
          accept='image/*'
          name='image'
          className={'avatar-uploader-3'}
          action={`${URL_API}icpa/uploads/image`}
          beforeUpload={beforeUploadImage}
          // customRequest={uploadFile}
          onChange={handleChangeFile}
          showUploadList={false}
          disabled={loadingImage || props?.disabled}
        >
          {!image && (
            <div
              className={
                props?.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              }
              style={{
                border: '1px dashed #8F9BBA',
                borderRadius: '8px',
                background: '#F4F7FE',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                minWidth: '100%',
                minHeight: '100%',
              }}
            >
              <img
                style={{ width: '24x', height: 'auto' }}
                src={'/images/sections-icon/upload.svg'}
                alt={'upload'}
              />
            </div>
          )}
        </Upload>
      </div>
      <div className='ml-10 pt-15'>
        {props?.label && (
          <div
            style={{
              fontSize: '16px',
              fontWeight: 500,
              color: '#1F263E',
              marginBottom: '4px',
            }}
          >
            {props?.label}
          </div>
        )}
        {props?.placeholder && (
          <div style={{ fontSize: '11px', color: '#57617C' }}>
            {props?.placeholder}
          </div>
        )}
      </div>
    </div>
  )
}
