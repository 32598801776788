/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react'
import 'react-phone-number-input/style.css'
import OverviewItemComponent from './overview/OverviewItemComponent'
import makeRequest from '../../../libs/request'
import { showErrorMessage } from '../../../actions/notification'
import NoDataComponent from './NoDataComponent'

export default function HistoryComponent(props) {
  const { value = {}, show = false, tab } = props
  const [certificationList, setCertificationList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const getCompanyInfo = async () => {
    setIsLoading(true)
    makeRequest('get', `icpa/certifications/company/${value?._id}/get-all`, {
      status: 'archived',
    })
      .then(({ data }) => {
        setIsLoading(false)
        if (data.signal) {
          let itemList = data.data
          if (itemList?.length > 0) {
            setCertificationList(itemList)
          } else {
            setCertificationList([])
          }
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    if (value?._id && show) {
      getCompanyInfo()
    }
  }, [value, show, tab])
  return (
    <>
      {certificationList?.length > 0 ? (
        certificationList.map((i, index) => {
          return (
            <div key={index} className='mt-24'>
              <OverviewItemComponent
                showDefault={index === 0}
                value={i}
                overview={false}
              />
            </div>
          )
        })
      ) : isLoading ? (
        <></>
      ) : (
        <NoDataComponent minHeight={'calc(100vh - 300px)'} />
      )}
    </>
  )
}
